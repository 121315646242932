import styled from 'styled-components'
import { MAX_CONTAINER } from '../utils/constants/post_page'
import { tokens } from '@JOTAJornalismo/jota-design-system'
import { LAYOUT_CONSTANT } from '../utils/constants'
import { verticalI } from '../components/home/second-scroll/Vertical.component'

const { spaces, colors, fonts, borders } = tokens
export const { SM_PX, MD_PX, LG_PX } = LAYOUT_CONSTANT.BREAKPOINTS

export const PostContainer = styled.div`
    max-width: ${MAX_CONTAINER.desktop};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: flex-start;
    padding-bottom: ${spaces.jotaSpacing44};

    .post-border {
        max-width: 1440px;
    }

    .section-container:nth-last-child(2) {
        .post-border {
            display: none;
        }
    }
`

export const PostHeader = styled.div`
    display: grid;
    flex-direction: row;
    grid-template-columns: 48% 52%;
    width: 100%;
    align-items: center;
    margin-bottom: 60px;

    img {
        height: 440px !important;
        max-height: none !important;
        object-fit: cover;
    }

    @media only screen and (max-width: ${MD_PX}) {
        display: flex;
        flex-direction: column;
        img {
            height: 400px !important;
        }
    }

    @media only screen and (max-width: ${SM_PX}) {
        img {
            height: 300px !important;
        }
    }
`

export const PostHeaderInfos = styled.div`
    padding: 40px 60px 0px 60px;
    background-color: ${colors.transparent.jotaTGrayLighter40};
    width: 100%;
    height: 440px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: ${MD_PX}) {
        padding: 44px 36px;
        height: auto;
    }

    @media only screen and (max-width: ${SM_PX}) {
        padding: 44px 16px;
    }
`

export const SponsorContainer = styled.div`
    @media only screen and (max-width: ${MD_PX}) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`

export const SpecialContentContainer = styled.div``

export const BodyCenter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 ${spaces.jotaSpacing44};

    @media only screen and (max-width: ${MD_PX}) {
        padding: 0 ${spaces.jotaSpacing36};
    }

    @media only screen and (max-width: ${SM_PX}) {
        padding: 0 ${spaces.jotaSpacing16};
    }
`

export const BreadcrumbContainer = styled.div`
    padding: ${spaces.jotaSpacing44} 0;

    @media only screen and (max-width: ${MD_PX}) {
        padding: ${spaces.jotaSpacing36} 0;
    }

    @media only screen and (max-width: ${SM_PX}) {
        padding: ${spaces.jotaSpacing24} 0;
    }

    & div[data-testid='breadcrumb-container'] div:first-of-type span:hover {
        cursor: pointer;
        text-decoration: underline;
        color: ${colors.gray.jotaGrayDarker};
    }

    & span:hover {
        color: #73757f;
    }
`

export const CardContainer = styled.div`
    padding-bottom: ${spaces.jotaSpacing8};
`
export const SpecialCardContainer = styled.article`
    padding-block: ${spaces.jotaSpacing24} ${spaces.jotaSpacing24};

    span {
        ${fonts.jotaOverlineSmall};
        text-transform: uppercase;
        color: ${colors.orange.jotaOrange};
    }
`

export const AuthorDisplayContainer = styled.div`
    display: inline;
`

export const AuthorContainer = styled.span<{
    onClick?: any
}>`
    ${fonts.jotaBody};
    color: ${colors.black.jotaBlack};
    font-weight: 700;
    margin-bottom: ${spaces.jotaSpacing8};
    width: fit-content;
`

export const AuthorContainerLink = styled.a`
    ${fonts.jotaBody};
    color: ${colors.black.jotaBlack};
    font-weight: 700;
    margin-bottom: ${spaces.jotaSpacing8};
    cursor: pointer;
    width: fit-content;

    @media only screen and (max-width: ${SM_PX}) {
        ${fonts.jotaBodySmall};
    }
`

export const TimestampContainer = styled.div<{
    hasSponsor?: boolean
    isSpecial?: boolean
}>`
    display: flex;
    flex-direction: row;
    ${fonts.jotaBodySmall};
    color: ${colors.gray.jotaGrayDarker};
    justify-content: space-between;
    margin-bottom: ${(props) => !props.hasSponsor && spaces.jotaSpacing44};
    padding-bottom: ${(props) =>
        props.hasSponsor ? '0' : spaces.jotaSpacing12};
    border-bottom: ${(props) =>
        props.hasSponsor
            ? 'none'
            : `1px solid ${colors.transparent.jotaTGrayLight40}`};

    @media only screen and (max-width: ${MD_PX}) {
        margin-bottom: ${(props) => props.hasSponsor && spaces.jotaSpacing36};
        border-bottom: ${(props) =>
            props.hasSponsor
                ? 'none'
                : `1px solid ${colors.transparent.jotaTGrayLight40}`};
    }

    @media only screen and (max-width: ${SM_PX}) {
        ${fonts.jotaBodyXSmall};
        margin-bottom: ${(props) => props.hasSponsor && '0'};
        border-bottom: ${(props) =>
            props.hasSponsor
                ? 'none'
                : `1px solid ${colors.transparent.jotaTGrayLight40}`};
    }
`

export const TimeStampColumn = styled.div`
    display: flex;
    flex-direction: column;
`

export const DateContainer = styled.div`
    display: flex;
    flex-direction: row;

    & > span.divisor {
        color: ${colors.transparent.jotaTBlack24};
        padding: 0 ${spaces.jotaSpacing4};
        display: inline-block;
    }

    span > span.divisor {
        color: ${colors.transparent.jotaTBlack24};
        padding: 0 ${spaces.jotaSpacing4};
        display: inline-block;
    }
`

export const UpdateContainer = styled.div`
    ${fonts.jotaBodyXSmall};
    color: ${colors.gray.jotaGrayDark};
    font-style: italic;
`

export const ImageNews = styled.div`
    padding-bottom: ${spaces.jotaSpacing44};

    & > img {
        width: 100%;
        border-radius: ${borders.jotaRadiusDefault};
    }
`
export const LegendImage = styled.div`
    ${fonts.jotaBodySmall};
    color: ${colors.gray.jotaGrayDark};
    border-left: 2px solid ${colors.orange.jotaOrange};
    padding-left: ${spaces.jotaSpacing16};
    margin-top: ${spaces.jotaSpacing8};
`

export const ImageContainer = styled.div`
    max-width: 600px;
`

export const BorderContainer = styled.div`
    border-bottom: 1px solid ${colors.transparent.jotaTGrayLight40};
    margin-block: 20px 44px;
    max-width: none;
    width: 100vw;
    align-self: center;
    padding: 0px 44px;
    box-sizing: border-box;
`

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        object-fit: cover;
     }

    h2 {
        @media only screen and (max-width: ${SM_PX}) {
            ${fonts.jotaHeading05};
        }
    }

    }

`

export const HTMLContent = styled.section<{
    isSpecial?: boolean
}>`
    ${fonts.jotaBodyLarge};
    color: ${colors.gray.jotaGrayDarker};

    margin-top: ${(props) => props.isSpecial && '24px'};

    a {
        color: ${colors.orange.jotaOrange};
        text-decoration: underline;
    }

    a.link_alert {
        color: ${colors.orange.jotaOrange};
    }

    p > em {
        display: ${(props) => props.isSpecial && 'flex'};
        flex-direction: ${(props) => props.isSpecial && 'column'};

        img {
            margin-block: ${(props) => props.isSpecial && '44px 24px'};
        }
    }

    iframe {
        width: 100%;
        max-width: ${(props) => props.isSpecial && '660px'};
        aspect-ratio: 16/9;

        html {
            width: 100%;
            max-width: ${(props) => props.isSpecial && '660px'};
        }
    }

    & .mcnButton {
        text-transform: uppercase;
        border: 0;
        border-radius: 4px;
        padding: 18px 24px;
        font-size: 20px;
        line-height: 24px;
    }

    & .mcnTextContent {
        padding-bottom: 24px !important;

        & h2 {
            ${fonts.jotaHeading04};
            padding: ${spaces.jotaSpacing24} 0;
            font-size: 2rem !important;
            line-height: 2.5rem !important;
            letter-spacing: 0 !important;
        }

        & h3 {
            ${fonts.jotaHeading05};
            padding-bottom: ${spaces.jotaSpacing8};
        }

        & h4 {
            ${fonts.jotaHeading06};
            padding-bottom: ${spaces.jotaSpacing8};
        }

        & h5 {
            ${fonts.jotaOverline};
            padding-bottom: ${spaces.jotaSpacing8};
        }

        & h6 {
            ${fonts.jotaOverlineSmall};
            padding-bottom: ${spaces.jotaSpacing8};
        }

        & a {
            font-weight: 500 !important;
            border-bottom: 1.5px solid #f05841 !important;
            text-decoration: none !important;
            transition: all ease-in-out 0.15s !important;

            &:hover {
                border-width: 2.5px !important;
                color: #f05841 !important;
            }
        }
    }

    & table {
        & tbody {
            & tr {
                & td {
                    ${fonts.jotaBodyLarge};
                    padding: 0 !important;
                    max-width: inherit;
                    & img {
                        margin: ${spaces.jotaSpacing16} 0;
                    }
                }
                ${fonts.jotaBodyLarge};
                padding: 0 !important;
                width: inherit;
                max-width: inherit;
            }

            & tr.author-card {
            }
            ${fonts.jotaBodyLarge};
            padding: 0 !important;
            width: inherit !important;
            max-width: inherit !important;
        }
    }

    & > h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding-bottom: ${spaces.jotaSpacing8};
        color: ${colors.black.jotaBlack};
    }

    & h2 {
        ${fonts.jotaHeading04};
        padding: ${spaces.jotaSpacing24} 0;
    }

    & h3 {
        ${fonts.jotaHeading05};
        padding-bottom: ${spaces.jotaSpacing8};
    }

    & h4 {
        ${fonts.jotaHeading06};
        padding-bottom: ${spaces.jotaSpacing8};
    }

    & h5 {
        ${fonts.jotaOverline};
        padding-bottom: ${spaces.jotaSpacing8};
    }

    & h6 {
        ${fonts.jotaOverlineSmall};
        padding-bottom: ${spaces.jotaSpacing8};
    }

    & > p > img {
        width: 100%;
        height: auto;
        margin: 20px 0;
    }

    & > p > a {
        color: ${colors.black.jotaBlack};
        font-weight: 500;
        text-decoration: none;
        border-bottom: 1.5px solid ${colors.orange.jotaOrange};
        transition: all ease-in-out 0.15s;
        max-width: ${(props) => props.isSpecial && '660px'};
        box-sizing: border-box;
        word-break: ${(props) => props.isSpecial && 'break-all'};

        &:hover {
            border-width: 2.5px;
            color: ${colors.orange.jotaOrange};
        }
    }

    & .notas-rodape {
        padding-top: ${spaces.jotaSpacing24};
        margin: 0;
        ${fonts.jotaBodySmall};
        color: ${colors.gray.jotaGrayDark};
        border-top: 1px solid ${colors.transparent.jotaTGrayLight40};
    }

    & .notas-rodape ~ .notas-rodape {
        border-top: none;
    }

    & .notas-rodape:last-child {
        padding-bottom: ${spaces.jotaSpacing44};
    }

    & blockquote {
        margin: 0 0 24px 0;
        border-top: 4px solid ${colors.orange.jotaOrange};
        border-bottom: 1px solid ${colors.orange.jotaOrange};
        padding: ${spaces.jotaSpacing16} 0 ${spaces.jotaSpacing44} 0;
        ${fonts.jotaQuote};
        color: ${colors.black.jotaBlack};

        & > p {
            margin: 0;
        }
    }

    & blockquote.tiktok-embed {
        border-top: inherit;
        border-bottom: inherit;
        padding: inherit;
        color: inherit;
    }

    .jota-article__reference {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    & > p > img,
    & > p img {
        width: 100%;
        height: auto;
        border-radius: ${borders.jotaRadiusDefault};
        max-width: ${(props) => props.isSpecial && '660px'};
    }

    figure {
        margin: ${(props) => props.isSpecial && '0'};
        margin-bottom: ${(props) => props.isSpecial && '44px'};
        max-width: 660px;
        width: ${(props) => props.isSpecial && '100% !important'};

        img {
            width: ${(props) => props.isSpecial && '100%'};
            height: ${(props) => props.isSpecial && 'auto'};
            border-radius: ${(props) => props.isSpecial && '4px'};
            max-width: ${(props) => props.isSpecial && '660px'};
            margin: ${(props) => props.isSpecial && '0'};
            border-radius: 4px;
        }

        figcaption {
            ${(props) => props.isSpecial && fonts.jotaBodySmall};
            border-left: ${(props) =>
                props.isSpecial && `2px solid ${colors.orange.jotaOrange}`};
            border-left: 2px solid #f05841;
            padding-left: 16px;
            margin-top: 8px;
            color: ${colors.gray.jotaGrayDark};
        }
    }

    & > p {
        margin: 0 0 24px 0;
    }

    & > p > #jotinha {
        width: 16px;
        height: 16px;
        margin: 0 0 0 8px;
        border-radius: 0;
    }

    span {
        font: inherit !important;
    }

    li a {
        color: ${colors.black.jotaBlack};
        font-weight: 500;
        text-decoration: none;
        border-bottom: 1.5px solid ${colors.orange.jotaOrange};
        transition: all ease-in-out 0.15s;

        &:hover {
            border-width: 2.5px;
            color: ${colors.orange.jotaOrange};
        }
    }

    h3 > a {
        color: ${colors.black.jotaBlack};
        margin: 24px 0;
        display: inline-block;
        text-decoration: underline;
        text-decoration-color: ${colors.orange.jotaOrange};
        text-decoration-thickness: 1.5px;

        &:hover {
            color: ${colors.orange.jotaOrange};
            text-decoration-thickness: 2px;
        }
    }
`

export const Attachment = styled.div`
    margin: 32px 0 0 0;
    padding-bottom: ${spaces.jotaSpacing44};
    border-bottom: 1px solid ${colors.transparent.jotaTGrayLight40};
`

export const AuthorAlert = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: ${spaces.jotaSpacing44};

    & > h2 {
        ${fonts.jotaOverlineCondensedLarge()};
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 120% */
        text-transform: uppercase;
        margin-left: ${spaces.jotaSpacing16};
    }
`

export const AuthorBioContainer = styled.div<{
    onClick?: any
}>`
    padding: ${spaces.jotaSpacing24} 0 ${spaces.jotaSpacing44} 0;
    display: flex;
    flex-direction: column;
    padding-top: ${spaces.jotaSpacing24};
`

export const AuthorsContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const DiplayAuthorsContainer = styled.div`
    border-top: 1px solid ${colors.transparent.jotaTGrayLight40};
`

export const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
`

export const CardCategory = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: ${spaces.jotaSpacing8};
    align-items: center;
    align-content: center;
    justify-content: flex-start;
`

export const PROFlag = styled.div`
    border-radius: 4px;
    border: 2px solid #d3d3d9;
    padding: 4px 6px;
    margin-left: 6px;

    ${fonts.jotaOverlineXSmall};
    color: ${colors.black.jotaBlack};
`

export const CardAuthorPRO = styled.div`
    ${fonts.jotaBody};
    color: ${colors.black.jotaBlack};
    font-weight: 700;
    margin-top: ${spaces.jotaSpacing24};
`

export const CardImage = styled.img`
    width: 32px;
    height: 32px;
`

const getColor = (color: verticalI) => {
    if (color === 'Tributos') {
        return colors.green.jotaGreen
    } else if (color === 'Saúde') {
        return colors.purple.jotaPurple
    } else {
        return colors.blue.jotaBlue
    }
}

export const CategoryName = styled.h5<{ color: verticalI }>`
    color: ${(props) => getColor(props.color)};
    margin: 0 ${spaces.jotaSpacing6};
    ${fonts.jotaHeading05};

    @media only screen and (max-width: ${SM_PX}) {
        ${fonts.jotaBody};
        font-weight: 700;
    }
`

export const SponsorParagraph = styled.p`
    ${fonts.jotaBodySmall};
    color: ${colors.gray.jotaGrayDark};
    padding: 16px;
    background: ${colors.transparent.jotaTGrayLighter40};
    border-radius: ${borders.jotaRadiusDefault};
    margin: 0 0 44px 0;

    & > a {
        font-weight: bold;
        border-bottom: 1.5px solid ${colors.orange.jotaOrange};
        color: ${colors.black.jotaBlack};

        &:hover {
            color: ${colors.orange.jotaOrange};
            border-bottom: 2px solid ${colors.orange.jotaOrange};
        }
    }
`
export const ProfileCardContainer = styled.a`
    padding: 16px;
    background: ${colors.transparent.jotaTGrayLighter40};
    border-radius: ${borders.jotaRadiusDefault};
    margin: 24px 0 44px 0;
    cursor: pointer;

    &:hover {
        background: ${colors.transparent.jotaTGrayLight40};
    }
`

export const SponsorRoot = styled.div`
    border-top: 1px solid ${colors.transparent.jotaTGrayLight40};
    margin-top: 12px;
`

export const SponsorBy = styled.p`
    ${fonts.jotaBodyXSmall};
    color: ${colors.gray.jotaGrayDark};
    text-align: center;
    margin: 0;
    padding: 12px 0 8px 0;
`

export const SponsorDescription = styled.p`
    ${fonts.jotaBodyXSmall};
    color: ${colors.gray.jotaGrayDark};
    text-align: center;
    margin: 0;
    margin-bottom: 8px;
`

export const SponsorBoxImage = styled.p`
    text-align: center;
    margin: 0;
`

export const SponsorDisclaimer = styled.p`
    ${fonts.jotaBodyLarge};
    color: ${colors.gray.jotaGrayDark};
    margin: 0;
    padding: 12px 0 16px 0;
`

export const SponsorBox = styled.div`
    display: grid;
    justify-content: center;
    padding: 12px 0px 12px 0px;
    margin-inline: 44px;
    border-bottom: 1px solid ${colors.transparent.jotaTGrayLight40};
`

export const SponsorImageContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center !important;
    padding-bottom: 12px;
    gap: 16px;
    margin-bottom: 44px;
    border-bottom: 1px solid ${colors.transparent.jotaTGrayLight40};

    @media only screen and (max-width: ${MD_PX}) {
        justify-content: flex-start;
    }
`

export const SponsorImage = styled.img`
    max-width: 180px;
    height: auto;
    width: max-content;
`

export const PostTitle = styled.h1`
    margin: 0;
    margin-top: 12px;
    ${fonts.jotaHeading02};

    @media only screen and (max-width: ${SM_PX}) {
        ${fonts.jotaHeading04};
    }
`
export const IframeContainer = styled.div`
    position: relative;
`
export const TagContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;

    div > span {
        color: ${colors.black.jotaBlack};
    }

    span {
        ${fonts.jotaOverlineSmall};
        text-transform: uppercase;
        color: ${colors.gray.jotaGrayDark};
        padding-top: 4px;
    }
`

export const ButtonContainer = styled.div``
